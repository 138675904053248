.inputAuth {
    width: 35px;
    height: 35px;
    margin: 5px;
    font-size: 30px;
    text-align: center;
}

.inputAuth:focus {
    outline: none !important;
    border:1px solid rgb(5, 97, 82);
    box-shadow: 0 0 10px #719ECE;
}